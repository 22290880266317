import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';
import Accordion from 'react-bootstrap/Accordion';

// markup
const MhaDocument = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | Payment Failure' />
      <Layout>
      <div className='container mah-document'>
          <div className='row '>
            <div className='col-md-12'>
              <section className='section'>
                <div className='section-title'>
                  <h3 className='text-start'>
                    <br /> MHA Document
                  </h3>
                </div>
                
                
                <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>2022-2023</Accordion.Header>
        <Accordion.Body>
       <a href='https://cdn-prod.heartfulness.org/mhadocument/HFI_FC_Donations_2022-23_MHA_Updated.pdf' target='_blank'> HFI FC Donations 2022-2023 MHA</a>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>2021-2022</Accordion.Header>
        <Accordion.Body>
       <a href='https://cdn-prod.heartfulness.org/mhadocument/HFI_FC_Donations_2021-22_MHA.pdf' target='_blank'> HFI FC Donations 2021-2022 MHA</a>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MhaDocument;
